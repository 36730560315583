import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import GutenbergBlocks from "../components/gutenberg-blocks";
import PropTypes from "prop-types";
import SEO from "../components/seo";
import { createBreadcrumbList } from "../utils/breadcrumb";

const Page = ({
  data: {
    wpPage: { blocks, title, excerpt, ancestors, seo, uri },
  },
}) => (
  <Layout>
    <SEO
      title={seo.title}
      description={seo.metaDesc || excerpt}
      breadcrumbs={createBreadcrumbList(ancestors, uri, title)}
    />
    <GutenbergBlocks blocks={blocks} />
  </Layout>
);

Page.propTypes = {
  data: PropTypes.object,
};

export default Page;

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      blocks {
        ...GutenbergBlocks
      }
      seo {
        metaDesc
        title
      }
      title
      excerpt
      uri
      ancestors {
        nodes {
          ... on WpPage {
            uri
            title
          }
        }
      }
    }
  }
`;
